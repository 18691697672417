import { trustpilotData } from '../../components/shared/TrustPilotWidget/const'

export const reviewsJsonLdData = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    applicationCategory: 'FinanceApplication',
    name: 'Freedom Debt Relief',
    offers: {
      price: '0',
      priceCurrency: 'USD',
    },
    aggregateRating: {
      reviewCount: trustpilotData.trustReviewsTotal,
      ratingValue: trustpilotData.trustScore,
    },
    operatingSystem: 'All',
    copyrightYear: new Date().getFullYear(),
    description:
      'Freedom Debt Relief helps clients lower their monthly debt payments without bankruptcy or loans.',
  }
}
