import React, { useCallback, useMemo, useRef } from 'react'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import menuData from 'src/components/layouts/ActualHeader/menuData'
import styles from './index.module.scss'
import NavBar from './NavBar'
import Img from '../../shared/Img'
import { useAnalytics } from '../../../common/analytics'
import PhoneNumber from '../../shared/PhoneNumber'
import { APPLY_FDR_URL } from '../../../common/constants'
import { useLeadId } from '../../../common/lead-id'
import ButtonLink from '../../shared/ButtonLink'
import {
  buildQueryString,
  controlPhone,
  defaultPhoneNumber,
  testPhone,
} from '../../../common/utils'

import useOnViewport from 'src/common/hooks/useOnViewport'

const StickyButtons = dynamic(() => import('../StickyButtons'))
const StickyButtonComponent = dynamic(() =>
  import('src/components/shared/StickyButtonComponent')
)

const Header = ({
  sideBarOpen,
  setSideBarOpen,
  withHeaderTel,
  locale,
  btnClassName,
  statusCode,
  elementsOnViewport,
  isOverHeader = false,
  isArticleOrBlogPage = false,
  isFloatingFooterEnabled = false,
  floatingFooterFlag = '',
  isHome,
}) => {
  const { track } = useAnalytics()
  const [isShowingTopElements] = useOnViewport('top-section')
  const [isHeroCard] = useOnViewport('hero-card')
  const leadId = useLeadId()

  const { query: baseQuery } = useRouter()
  const flotingPhoneNumber = isHome
    ? defaultPhoneNumber
    : isFloatingFooterEnabled
    ? testPhone
    : controlPhone
  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )
  const headerBannerText = sideBarOpen ? 'hidden' : 'hidden md:block lg:p-1'
  const isShowingElements = isShowingTopElements || isHeroCard

  const handleButtonClick = useCallback(
    //todo changed as CTA button, but destination url is https://fdr-dev.programdashboard.com/login
    (href, name, section, btn = true, callUs = false) => {
      const data = {
        nav_link_section: section,
        click_type: btn ? 'Button Click' : 'Phone Click',
        click_id: name,
        click_url: href,
        click_text: `FDR-Web | ${name}`,
        track_event: 'global_header',
      }
      if (callUs) {
        track(
          data,
          {
            experimentSection: 'clientLogin',
            event_type: 'start',
            variation: 'control',
          },
          'click',
          statusCode
        )
      } else {
        track(data, {}, 'click', statusCode)
      }
    },
    [track, statusCode]
  )

  const handleLogoClick = useCallback(() => {
    const data = {
      nav_link_section: 'Header',
      click_type: 'Logo Click',
      click_id: 'Freedom Debt Relief',
      click_url: '/',
      click_text: 'FDR-Web | Freedom Debt Relief',
      track_event: 'global_header',
      event_type: 'track',
    }

    track(data, {}, 'click', statusCode)
  }, [track, statusCode])

  const headerRef = useRef()

  const NavBarNavigation = ({ showButtonOptions }) => (
    <div className="col-span-full col-start-1 m-auto lg:col-span-12">
      <div className={`${styles.wrapper} flex`}>
        <NextLink
          href="/"
          prefetch={false}
          shallow={true}
          data-testid="logo-link"
          onClick={handleLogoClick}
        >
          <div className="pl-8 lg:pl-0">
            <Img
              priority={true}
              dataTestId="fdr-logo"
              src="/next-assets/icons/fdr-logo.svg"
              alt="Freedom Debt Relief"
              className="h-12 w-28"
              layout="responsive"
              styles={{
                position: 'relative',
                height: '3rem',
                width: '7rem',
              }}
            />
          </div>
        </NextLink>
        <NavBar
          menuData={menuData}
          sideBarOpen={sideBarOpen}
          setSideBarOpen={setSideBarOpen}
          locale={locale}
          btnClassName={btnClassName}
          statusCode={statusCode}
          showButtonOptions={showButtonOptions}
        />
      </div>
    </div>
  )

  return (
    <>
      <div className="mb-0 lg:mb-24" />
      <header
        ref={headerRef}
        className={`fixed top-0 z-100 w-full justify-start bg-white py-2 duration-300 lg:static lg:justify-center ${
          isFloatingFooterEnabled
            ? isOverHeader && !elementsOnViewport
              ? 'hidden'
              : 'flex'
            : 'flex'
        }`}
        data-testid="app-header"
        id="app-header"
      >
        <div className="gap-x-primary col-span-full grid grid-cols-4 py-2 text-ms text-gray-510 md:grid-cols-10 lg:grid-cols-12 lg:py-0">
          <NavBarNavigation showButtonOptions />
          <div
            className={`col-span-3 hidden pr-2 lg:order-first lg:col-span-12 ${
              elementsOnViewport ? 'lg:block' : ''
            }`}
          >
            {withHeaderTel && (
              <div
                className={headerBannerText}
                data-testid="header-banner-text"
              >
                <p className="text-right">
                  <span className="text-xs text-black-light">
                    Talk to a certified debt consultant
                  </span>
                  <PhoneNumber
                    href={`tel:${flotingPhoneNumber}`}
                    className="ml-2 text-base font-semibold text-black-light no-underline"
                    text={flotingPhoneNumber}
                    isTrack={true}
                    statusCode={statusCode}
                    nav_link_section="Above header"
                  />
                </p>
              </div>
            )}
          </div>
        </div>
        {((isFloatingFooterEnabled
          ? elementsOnViewport && !isOverHeader
          : true) ||
          isHome ||
          elementsOnViewport) && (
          <div
            className={`mx-2 flex flex-equal items-center justify-end lg:hidden`}
          >
            <ButtonLink
              href={`tel:${flotingPhoneNumber}`}
              className="z-10 mr-3 whitespace-nowrap rounded-lg bg-blue-540 px-2.5 py-1.5 text-13 font-bold leading-22 text-blue-base"
              onClick={(href) =>
                handleButtonClick(href, 'Call Us', 'Mobile header', false, true)
              }
            >
              Call Us
            </ButtonLink>
            <ButtonLink
              href={`${APPLY_FDR_URL()}?${query}`}
              target="_blank"
              rel="noopener noreferrer"
              className={`whitespace-nowrap rounded-lg border-2 border-blue-base bg-white px-2.5 py-1 text-13 font-bold text-blue-base lg:ml-4 ${btnClassName}`}
              onClick={(href) =>
                handleButtonClick(href, 'See if you qualify', 'Header')
              }
            >
              See if you qualify
            </ButtonLink>
          </div>
        )}
      </header>
      {isFloatingFooterEnabled ? (
        <StickyButtons
          headerHeight={headerRef.current?.clientHeight || 0}
          isOverHeader={isOverHeader}
          elementsOnViewport={isShowingElements}
          handleLogoClick={handleLogoClick}
          handleButtonClick={handleButtonClick}
          navBarElement={<NavBarNavigation showButtonOptions={false} />}
          phoneNumberText={flotingPhoneNumber}
          isArticleOrBlogPage={isArticleOrBlogPage}
        />
      ) : isArticleOrBlogPage &&
        !isShowingElements &&
        floatingFooterFlag === 'control' ? (
        <StickyButtonComponent
          parentClassName="sm:hidden"
          bgColor="bg-blue-550"
        />
      ) : null}
    </>
  )
}

Header.defaultProps = {
  sideBarOpen: false,
  withHeaderTel: true,
  locale: 'en',
  btnClassName: undefined,
  isFloatingFooterEnabled: false,
  isHome: false,
}

Header.propTypes = {
  sideBarOpen: PropTypes.bool,
  withHeaderTel: PropTypes.bool,
  locale: PropTypes.string,
  setSideBarOpen: PropTypes.func,
  btnClassName: PropTypes.string,
  statusCode: PropTypes.number,
  isFloatingFooterEnabled: PropTypes.bool,
  floatingFooterFlag: PropTypes.string,
  isHome: PropTypes.bool,
}

export default Header
