'use client'

import { useMemo } from 'react'
import { parseCookies } from '../utils'

export const usePersonalizedData = () => {
  const cookieStr = parseCookies(global.window ? document.cookie : '')
  const cookiesPznData = useMemo(() => {
    try {
      const experiments = JSON.parse(cookieStr?.experiments ?? '[]')

      return experiments.find(
        (experiment) => experiment.experiment_id === 'fdr-pzn-home-page'
      )
    } catch {
      return null
    }
  }, [cookieStr])

  return cookiesPznData
}
