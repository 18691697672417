export const hostNameToDashboardEnvMap = {
  localhost: '-dev',
  'fdr.dev.fdrgcp.com': '-dev',
  'fdr.stg.fdrgcp.com': '-stg',
  'fdr.sbx.fdrgcp.com': '-stg',
}

export const getDashboardPath = (host) => {
  const postFix = hostNameToDashboardEnvMap[host]

  return `https://fdr${postFix || ''}.programdashboard.com/login`
}
