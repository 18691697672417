import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useAnalytics } from '../../../common/analytics'
import Link from 'next/link'

const PhoneNumber = ({
  href,
  ariaLabel,
  className,
  text,
  isTrack,
  statusCode,
  speakClassName,
  speakText,
  nav_link_section,
}) => {
  const { track } = useAnalytics()

  const handleClick = useCallback(
    (e) => {
      const data = {
        nav_link_section,
        click_type: 'Phone Click',
        click_id: text,
        click_text: `FDR-Web | ${text}`,
        track_event: 'phone_click',
        event_type: 'conversion',
      }

      if (isTrack) {
        track(data, {}, 'click')
      }

      return null
    },
    [text, track, isTrack, nav_link_section]
  )

  return (
    <>
      {speakText ? <span className={speakClassName}>{speakText}</span> : null}
      <Link
        href={href}
        aria-label={ariaLabel}
        className={`${className} underline underline-offset-4`}
        onClick={handleClick}
      >
        {text}
      </Link>
    </>
  )
}

PhoneNumber.defaultProps = {
  ariaLabel: 'phone number',
  className: '',
  isTrack: false,
  nav_link_section: 'Page',
}

PhoneNumber.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  isTrack: PropTypes.bool,
  statusCode: PropTypes.number,
  speakClassName: PropTypes.string,
  speakText: PropTypes.string,
  nav_link_section: PropTypes.string,
}

export default React.memo(PhoneNumber)
