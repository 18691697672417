const fdrProgram = {
  id: 'program',
  colNo: 1,
  subItems: {
    how: { name: 'How It Works', url: '/how-it-works/' },
    who: { name: 'Who We Help', url: '/who-we-help/' },
    why: { name: 'Why Choose Freedom Debt Relief', url: '/why-were-better/' },
    testimonials: { name: 'Customer Reviews', url: '/testimonials/' },
    faq: { name: 'FAQs', url: '/faq/' },
    scam: { name: 'Is Freedom Debt Relief a Scam?', url: '/scam/' },
  },
}
const debtResources = {
  id: 'resources',
  colNo: 2,
  subItems: {
    how: {
      col: 'left',
      name: 'How to Pay Off Debt',
      url: '/debt-solutions/how-to-pay-off-debt/',
    },
    consolidation: {
      col: 'left',
      name: 'Debt Consolidation',
      url: '/debtconsolidation/',
    },
    settlement: {
      col: 'left',
      name: 'Debt Settlement',
      url: '/debt-solutions/debt-settlement/',
    },
    management: {
      col: 'left',
      name: 'Debt Management',
      url: '/debt-solutions/debt-management/',
    },
    near: {
      col: 'left',
      name: 'Debt Consolidation Near Me',
      url: '/debt-consolidation-near-me/',
    },
    counseling: {
      col: 'left',
      name: 'Credit Counseling',
      url: '/debt-solutions/credit-counseling/',
    },
    bankruptcy: {
      col: 'left',
      name: 'Bankruptcy',
      url: '/debt-solutions/bankruptcy/',
    },
    blog: { col: 'left', name: 'Blog', url: '/blog/' },
  },
}
const ourCompany = {
  id: 'company',
  colNo: 1,
  subItems: {
    about: { name: 'About Us', url: '/about/' },
    contact: { name: 'Contact Us', url: '/contact/' },
    media: { name: 'Media Assets', url: '/media-assets/' },
    press: { name: 'Press Releases', url: '/press-releases/' },
    espanol: { name: 'Espanol', url: '/es/espanol/' },
    unsubscribe: {
      name: 'Unsubscribe',
      url: 'https://preferences.freedomdebtrelief.com/email',
    },
    sitemap: { name: 'Sitemap', url: '/sitemap/' },
  },
}

export const footerData = [fdrProgram, debtResources, ourCompany]
