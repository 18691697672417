import { useEffect, useState } from 'react'

export default function useOnViewport(elementId, fully) {
  const [onViewport, setOnViewport] = useState(false)
  const [isUnderViewport, setIsUnderViewport] = useState(true)

  const handleScroll = () => {
    const element = document.getElementById(elementId)
    const top = element?.getBoundingClientRect().top
    const bottom = element?.getBoundingClientRect().bottom
    const height = element?.getBoundingClientRect().height
    const windowHeight = window.innerHeight
    const topIsOnViewport = 0 < top && top < windowHeight
    const bottomIsOnViewport = 0 < bottom && bottom < height

    if (fully) {
      setOnViewport(
        bottom <= (windowHeight || document.documentElement.clientHeight)
      )
    } else {
      setOnViewport(topIsOnViewport || bottomIsOnViewport)
    }

    setIsUnderViewport(top >= 0)
  }

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll, false)

    return () => window.removeEventListener('scroll', handleScroll, false)
  }, [])

  return [onViewport, isUnderViewport]
}
