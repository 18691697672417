import { useCallback, useEffect, useState } from 'react'

export default function useOnViewPortCtas(elementsIds, tagName) {
  const [onViewport, setOnViewport] = useState(true)

  const handleScroll = useCallback(() => {
    const elementsOnView = {}

    const elementsOnViewPort = (element) => {
      if (element) {
        const { top, bottom, height } = element.getBoundingClientRect()
        const windowHeight = window.innerHeight
        const topIsOnViewport = 0 < top && top < windowHeight
        const bottomIsOnViewport = 0 < bottom && bottom < height

        elementsOnView['value'] =
          elementsOnView['value'] || topIsOnViewport || bottomIsOnViewport
      }
    }

    elementsIds.forEach((elementId) => {
      const element = document.getElementById(elementId)
      elementsOnViewPort(element)
    })

    if (!Object.values(elementsOnView).length) {
      const element = document.querySelector(tagName)
      elementsOnViewPort(element)
    }

    setOnViewport(elementsOnView.value)
  }, [elementsIds, tagName])

  useEffect(() => {
    handleScroll()
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  return [onViewport, setOnViewport]
}
