import React from 'react'
import Head from 'next/head'
import PropTypes from 'prop-types'

const CustomJsonLd = ({ id, data }) => (
  <Head>
    <script
      type="application/ld+json"
      id={id}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      key={`jsonLd-${id}`}
    />
  </Head>
)

export default CustomJsonLd

CustomJsonLd.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
