const menuData = [
  {
    id: 'nav-item-fdr-program',
    name: 'The FDR Program',
    subItems: [
      { id: 0, name: 'How It Works', url: '/how-it-works/' },
      { id: 1, name: 'Who We Help', url: '/who-we-help/' },
      {
        id: 2,
        name: 'Why Choose Freedom Debt Relief',
        url: '/why-were-better/',
      },
      { id: 3, name: 'Testimonials', url: '/testimonials/' },
      { id: 4, name: 'FAQ', url: '/faq/' },
    ],
  },
  {
    id: 'nav-item-debt-resources',
    name: 'Debt Resources',
    subItems: [
      {
        id: 0,
        type: 'title',
        name: 'Popular Articles',
      },
      {
        id: 1,
        name: 'Debt Consolidation',
        url: '/debtconsolidation/',
      },
      {
        id: 2,
        name: 'Debt Consolidation Near Me',
        url: '/debt-consolidation-near-me/',
      },
      {
        id: 3,
        name: 'Pros and Cons of Credit Counseling',
        url: '/learn/debt-solutions/credit-card-counseling-pros-cons/',
      },
      {
        id: 4,
        name: 'Debt Relief Overview',
        url: '/debt-relief/',
      },
      {
        id: 5,
        name: 'Credit Card Debt Relief',
        url: '/credit-card-debt/credit-card-debt-relief/',
      },
      {
        id: 6,
        name: 'What is a Debt Relief Program?',
        url: '/debt-relief-program/',
      },
    ],
  },
  {
    id: 'nav-item-blog',
    name: 'Blog',
    subItems: [
      { id: 0, name: 'All Categories', url: '/blog/' },
      { id: 1, name: 'Credit Card Debt', url: '/l/credit-card-debt/' },
      { id: 2, name: 'Debt Consolidation', url: '/l/debtconsolidation/' },
      {
        id: 3,
        name: 'Debt Consolidation Near Me',
        url: '/l/debt-consolidation-near-me/',
      },
      { id: 4, name: 'Debt Solutions', url: '/l/debt-solutions/' },
      { id: 5, name: 'Personal Finance', url: '/l/personal-finance/' },
      { id: 6, name: 'Loans', url: '/l/loans/' },
      { id: 7, name: 'Credit Score', url: '/l/credit-score/' },
    ],
  },
  {
    id: 'nav-item-our-company',
    name: 'Our Company',
    subItems: [
      { id: 0, name: 'About Us', url: '/about/' },
      { id: 1, name: 'Contact Us', url: '/contact/' },
      { id: 2, name: 'Media Assets', url: '/media-assets/' },
      { id: 3, name: 'Press Releases', url: '/press-releases/' },
    ],
  },
  {
    id: 'nav-item-espanol',
    name: 'Español',
    url: '/es/espanol/',
  },
]

export default menuData
