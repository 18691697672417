import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { formatUrl } from '../../../common/utils'
import { externalsRegex } from '../HyperLink/expectedDomains'

const UnCrawlableLink = ({ href, target, label, onClick, className, id }) => {
  const path = formatUrl(href)

  const isExternalLink = useCallback((url) => !externalsRegex.test(url), [])

  const handleClick = useCallback(async () => {
    if (typeof onClick === 'function') {
      onClick()
    }

    window.location.href = href
  }, [href, onClick])

  const externalLinkProps = useMemo(() => {
    let obj
    if (target) {
      obj = {
        target,
      }
    } else {
      obj = isExternalLink(path)
        ? {
            rel: 'nofollow',
            target: '_blank',
          }
        : null
    }

    return obj
  }, [isExternalLink, path, target])

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <span
      id={`${id}-${label.replaceAll(' ', '-')}`}
      className={`cursor-pointer ${className}`}
      onClick={handleClick}
      {...externalLinkProps}
    >
      {label}
    </span>
  )
}

UnCrawlableLink.displayName = 'NoCrawlableLink'

UnCrawlableLink.defaultProps = {
  href: undefined,
  target: '_self',
  onClick: null,
  className: '',
  id: 'UnCrawlableLink',
}
UnCrawlableLink.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  target: PropTypes.string,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
}

export default React.memo(UnCrawlableLink)
