import PropTypes from 'prop-types'

// NOTE: Remove eslint disable when more types are added.

/* eslint-disable import/prefer-default-export */
export const renderable = PropTypes.oneOfType([
  // A React element.
  PropTypes.element,
  // Anything that can be rendered: numbers, strings,
  // elements or an array(or fragment) containing these types.
  PropTypes.node,
  // A functional React component
  PropTypes.func,

  PropTypes.object,
])
/* eslint-enable import/prefer-default-export */
